<template>
    <el-dialog
            title="提交确认"
            :visible.sync="dialogVisible"
            width="480px"
            top="0"
            :close-on-click-modal="false"
            :before-close="onCancel"
    >
        <div class="info_2">
            请确认所填信息无误，一经提交，不可修改。确认提交吗？
        </div>

        <span slot="footer" class="dialog-footer">
          <!-- 去注册区域 -->
          <el-button class="cancel" @click="onCancel()">取消</el-button>
          <el-button class="confirm" @click="onConfirm()">确认</el-button>
        </span>
    </el-dialog>
</template>

<script>
    export default {
        name: "ConfirmDialog",
        props: {
            dialogVisibleFu: Boolean,
        },
        data() {
            return {
                dialogVisible: this.dialogVisibleFu,
            };
        },
        methods: {
            onCancel() {
                this.dialogVisible = false;
                this.$emit('closeDialog', false, false);
            },
            onConfirm() {
                this.dialogVisible = false;
                this.$emit('closeDialog', false, true);
            },
        },
        watch: {
            dialogVisibleFu() {
                this.dialogVisible = this.dialogVisibleFu;
            },
        },
    };
</script>

<style scoped lang="scss">
    @import "../../../assets/scss/dialog";

    ::v-deep .el-dialog__body {
        padding: 0 48px 30px 48px !important;
    }

    ::v-deep .el-dialog__footer {
        padding: 20px 0 40px !important;
        text-align: center;
    }

    ::v-deep .el-icon-close:before {
        content: "";
    }
</style>
