<template>
    <div id="container">
        <el-main>
            <!-- 面包屑导航区域 -->
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }">考试列表</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/signUp/viewInfo' }">查看报名信息</el-breadcrumb-item>
                <el-breadcrumb-item>立即报备</el-breadcrumb-item>
            </el-breadcrumb>

            <!-- 主干区域 -->
            <div class="formBox">
                <div class="header">
                    填写报备信息
                </div>
                <div class="prompt_message">
                    本次报备是面向：处于中高风险地区所在城市或在考试开始前14天有中高风险地区所在城市旅居史的考生。请如实填写以下信息，如有虚假，后果自负。
                </div>
                <el-form :model="formData" :rules="rules" ref="formRef" :inline="false" label-position="top">
                    <div style="width: 779px;">
                        <div class="item-title">
                            个人信息
                        </div>
                        <el-row :gutter="22">
                            <el-col :span="12">
                                <el-form-item label="姓名" prop="real_name">
                                    <el-input v-model.trim="formData.real_name" placeholder="请输入姓名"
                                              clearable></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="手机号码（注：用于接收短信通知，请务必核实）" prop="phone">
                                    <el-input v-model.trim="formData.phone" placeholder="请输入手机号码" clearable></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <div class="item-title">
                            居住地信息
                        </div>
                        <el-row :gutter="22">
                            <el-col :span="12">
                                <el-row :gutter="22">
                                    <el-col :span="12">
                                        <el-form-item label="居住地所在省、市、区(县)" prop="residence_information.province">
                                            <el-select
                                                    v-model.trim="formData.residence_information.province"
                                                    placeholder="请选择省份"
                                                    :popper-append-to-body="false"
                                                    @change="selectProvince"
                                                    clearable
                                            >
                                                <el-option
                                                        v-for="item in provinceData"
                                                        :key="item.id"
                                                        :label="item.name"
                                                        :value="item.id"
                                                >
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label=" " prop="residence_information.city" class="label_no">
                                            <el-select
                                                    v-model.trim="formData.residence_information.city"
                                                    placeholder="请选择城市"
                                                    :popper-append-to-body="false"
                                                    clearable
                                                    @change="selectCity"
                                            >
                                                <el-option
                                                        v-for="item in cityList"
                                                        :key="item.id"
                                                        :label="item.name"
                                                        :value="item.id"
                                                >
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label=" " prop="residence_information.area" class="label_no">
                                    <el-input v-model.trim="formData.residence_information.area"
                                              placeholder="请输入所在区(县)" clearable></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item label="居住地详细地址" prop="residence_information.street">
                                    <el-input v-model.trim="formData.residence_information.street"
                                              placeholder="请输入现居地详细地址" clearable></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <div class="item-title">
                            14天旅居史<span>（如14天内有中高风险地区旅居史，请填写下方信息）</span>
                        </div>
                        <el-row :gutter="22" v-for="(item,index) in formData.sojourn_history_14" :key="index">
                            <el-col :span="12">
                                <el-row :gutter="22">
                                    <el-col :span="12">
                                        <el-form-item :label="`地区${index+1}`">
                                            <el-select
                                                    v-model.trim="formData.sojourn_history_14[index].province"
                                                    placeholder="请选择省份"
                                                    :popper-append-to-body="false"
                                                    clearable
                                                    @change="selectHistoryProvince($event, index)"
                                            >
                                                <el-option
                                                        v-for="item in provinceDataHis"
                                                        :key="item.province_id"
                                                        :label="item.province_name"
                                                        :value="item.province_id"
                                                >
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label=" " class="label_no">
                                            <el-select
                                                    v-model.trim="formData.sojourn_history_14[index].city"
                                                    placeholder="请选择城市"
                                                    :popper-append-to-body="false"
                                                    clearable
                                                    @change="selectHistoryCity($event, index)"
                                            >
                                                <el-option
                                                        v-for="item in historyCityList[index]"
                                                        :key="item.city_id"
                                                        :label="item.city_name"
                                                        :value="item.city_id"
                                                >
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label=" " :prop="`sojourn_history_14.${index}.area`" class="label_no">
                                    <el-input v-model.trim="formData.sojourn_history_14[index].area"
                                              placeholder="请输入旅居地所在区(县)" clearable></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <div class="add_place">
                            <span @click="addPlace">添加地区</span>
                        </div>
                        <div class="item-title">
                            证明材料
                        </div>
                    </div>

                    <el-form-item label="上传证明材料图片" prop="prove_pics">
                        <el-row :gutter="24">
                            <el-col style="width: 790px;padding-right: 0;" :span="24" class="cc">
                                1.请上传1-6张图片，包括但不限于：健康码、通信大数据行程卡、当地限制出行的政策文件
                            </el-col>
                            <el-col style="width: 790px;padding-right: 0;" :span="24" class="cc">
                                2.每张图片大小不超过10M，支持JPG、JPEG、PNG等格式
                            </el-col>
                            <el-col :span="8" v-show="fileList.length < 6">
                                <el-upload
                                        action="#"
                                        :show-file-list="false"
                                        drag
                                        :limit="6"
                                        :file-list="fileList"
                                        :http-request="uploadPicture">
                                    <i class="el-icon-upload"
                                       style="font-size: 50px; color: #afa7a7; margin-top: 25px"></i>
                                    <div class="el-upload__tip">.JPG .PNG</div>
                                    <div class="el-upload__text" style="margin-top: 10px">点击上传文件</div>
                                </el-upload>
                            </el-col>
                            <el-col :span="8" v-show="fileList.length > 0" v-for="(item,index) in fileList"
                                    :key="index">
                                <el-image :src="item.url"></el-image>
                                <i class="el-icon-close" @click="removeImage(index)"></i>
                            </el-col>
                        </el-row>
                    </el-form-item>

                    <el-form-item class="btn">
                        <el-button class="confirm" @click="submitForm()">提交</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-main>

        <!--提交确认弹框-->
        <confirm-dialog :dialogVisibleFu="dialogVisibleFu" @closeDialog="closeDialog"></confirm-dialog>
    </div>
</template>

<script>
    import ConfirmDialog from "c/index/signUp/ConfirmDialog"
    import {
        getHomeApply,
        updateHomeApply,
        checkHomeApplyProve,
        getCityList,
        getProvinceList,
        getProAndCityHistory
    } from "r/index/signUp";
    import {upload} from "r/request";

    export default {
        name: "ReportInfo",
        components: {
            ConfirmDialog
        },
        data() {
            // 区、县验证规则
            let areaLen = (rule, value, callback) => {
                console.log(value)
                if (!value) {
                    return callback(new Error('请输入所在区(县)'));
                } else if (value.length > 10) {
                    return callback(new Error('请填写10个字以内的区(县)'));
                } else {
                    callback();
                }
            };
            let areaLens = (rule, value, callback) => {
                console.log(value)
                if (value && value.length > 10) {
                    return callback(new Error('请填写10个字以内的区(县)'));
                } else {
                    callback();
                }
            };
            // 详细地址验证规则
            let streetLen = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('请输入现居地详细地址'));
                } else if (value.length > 50) {

                    return callback(new Error('请填写50字以内的详细地址'));
                } else {
                    callback();
                }
            };
            // 手机号验证规则
            let phone = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('请输入手机号码'));
                } else {
                    const reg = /^1[3|4|5|6|7|8|9]\d{9}$/;
                    if (reg.test(value)) {
                        callback();
                    } else {
                        return callback(new Error('请输入正确的手机号码'));
                    }
                }
            };
            return {
                dialogVisibleFu: false,// 控制提交确认弹框显示隐藏
                formData: {
                    exam_uuid: null,
                    real_name: null,
                    phone: null,
                    residence_information: {
                        province: null,
                        city: null,
                        area: null,
                        street: null
                    },
                    sojourn_history_14: [],
                    prove_pics: []
                },
                rules: {
                    real_name: [
                        {required: true, message: '请输入姓名', trigger: 'blur'}
                    ],
                    phone: [
                        {required: true, message: '请输入手机号码', trigger: 'blur'},
                        {validator: phone, required: true, trigger: 'blur'}
                    ],
                    residence_information: {
                        province: [
                            {required: true, message: '请选择省份', trigger: 'blur'}
                        ],
                        city: [
                            {required: true, message: '请选择城市', trigger: 'blur'}
                        ],
                        area: [
                            {required: true, message: '请输入所在区(县)', trigger: 'blur'},
                            {validator: areaLen, required: true, trigger: 'blur'}
                        ],
                        street: [
                            {required: true, message: '请输入现居地详细地址', trigger: 'blur'},
                            {validator: streetLen, required: true, trigger: 'blur'}
                        ],
                    },
                    sojourn_history_14: [],
                    prove_pics: [
                        {required: true, message: '请上传证明材料图片', trigger: 'blur'}
                    ]
                },
                // 14天旅居史对象
                sojourn_history_obj: {
                    province: null,
                    city: null,
                    area: null
                },
                // 14天旅居史rule
                sojourn_history_rules: {
                    province: [
                        {message: '请选择省份', trigger: 'blur'}
                    ],
                    city: [
                        {message: '请选择城市', trigger: 'blur'}
                    ],
                    area: [
                        {validator: areaLens, required: true, trigger: 'blur'}
                    ],
                },
                file: "",
                fileList: [],
                provinceData: {}, //接口获取的全国省份数据
                cityData: {}, //接口获取的全国城市数据
                cityList: [],//居住地城市
                provinceDataHis: {}, //接口获取的全国疫情区省份数据
                cityDataHis: {}, //接口获取的全国疫情区城市数据
                historyCityList: [],//14天旅居史城市

            };
        },
        created() {
            this.provinceData = JSON.parse(window.localStorage.getItem("provinceData"));
            this.cityData = JSON.parse(window.localStorage.getItem("cityData"));
            if (this.provinceData == null || !this.provinceData || Object.values(this.provinceData).length == 0) {
                this.getProvinceLists(); //获取全部省份
            }
            if (this.cityData == null || !this.cityData || Object.values(this.cityData).length == 0) {
                this.getCityLists(); //获取全国城市
            }
            this.getProAndCityHistory();
            setTimeout(() => {
                this.getHomeApply();
            }, 100)
        },
        methods: {
            // 子组件触发，关闭弹框触发事件
            closeDialog(val, type) {
                console.log(val, type)
                this.dialogVisibleFu = val;
                if (type) {
                    this.submitRequest();
                }
            },
            // 修改或新增当前报备请求
            submitRequest() {
                let obj = this.formData;
                let arr = [];
                obj.sojourn_history_14.forEach(item => {
                    if (item['area'] && item['area'].length > 0) {
                        item['area'] = item['area'].trim();
                    }
                    if (!item['area'] && !item['province'] && !item['city']) {
                    } else {
                        arr.push(item);
                    }
                })
                obj['sojourn_history_14'] = arr;

                updateHomeApply(obj).then(res => {
                    console.log(res);
                    let resData = res.data;
                    if (resData.code != 200) {
                        return this.$message.error(resData.message);
                    }
                    this.$router.replace('/signUp/viewInfo');
                }).catch(err => {
                    console.log(err)
                })
            },
            // 提交
            submitForm() {
                this.$refs['formRef'].validate((valid) => {
                    if (!valid) return;
                    this.dialogVisibleFu = true;
                });
            },

            // 上传图片
            uploadPicture(params) {
                const isLt10M = (params.file.size / 1024 / 1024) < 10;
                if (!isLt10M) {
                    this.$message.warning('上传文件大小不能超过10MB！');
                    return;
                }
                this.file = params.file;
                let raw = params.file;
                let image = {
                    name: raw.name,
                    size: raw.size,
                    pro: 2,
                    type: raw.type,
                };
                this.getOssConfig(image, params.file);
            },
            // 获取上传图片ossconfig配置
            async getOssConfig(image, file) {
                await checkHomeApplyProve({
                    exam_uuid: window.localStorage.getItem("exam_uuid"),
                    image: image
                })
                    .then((res) => {
                        console.log(res);
                        if (res.data.code === 200) {
                            let oss_url = res.data.config.host;
                            let filename = res.data.config.file;
                            let formData = new FormData();
                            formData.append("OSSAccessKeyId", res.data.config.accessid);
                            formData.append("policy", res.data.config.policy);
                            formData.append("signature", res.data.config.signature);
                            formData.append("filename", res.data.config.file);
                            formData.append("key", res.data.config.savefile);
                            formData.append("callback", res.data.config.callback);
                            formData.append("success_action_status", 200);
                            formData.append("file", file);
                            console.log("已经上传上", this.formData.prove_pics);
                            // 上传
                            upload(oss_url, formData)
                                .then((ress) => {
                                    console.log(ress);
                                    if (ress.data.code == 200) {
                                        this.loading = false;
                                        let obj = {
                                            url: filename + `?t=${new Date().getTime()}`,
                                            status: "done",
                                        }
                                        this.fileList.push(obj);
                                        this.formData.prove_pics.push(ress.data.picture_path);
                                        console.log(this.fileList)
                                        this.$message.success("上传成功");

                                    } else {
                                        this.$message.error(ress.data.message);
                                    }
                                })
                                .catch((err) => {
                                    this.$message.error(err);
                                });
                        } else {
                            this.$message.error(res.data.message);
                        }
                    })
                    .catch((err) => {
                        this.$message.error(err);
                    });
            },
            // 删除图片
            removeImage(index) {
                let arrF = [];
                let arrP = [];
                for (let k in this.fileList) {
                    if (k != index) {
                        arrF.push(this.fileList[k]);
                        arrP.push(this.fileList[k].url);
                    }
                }
                this.fileList = arrF;
                this.formData.prove_pics = arrP;
            },

            // 添加地区
            addPlace() {
                if (this.formData.sojourn_history_14.length < 5) {
                    let obj1 = {
                        province: null,
                        city: null,
                        area: null
                    }
                    let obj2 = this.sojourn_history_rules;
                    this.formData.sojourn_history_14.push(obj1);
                    this.rules.sojourn_history_14.push(obj2);
                } else {
                    this.$message.error("旅居地区最多可添加5个");
                }
            },
            // 获取当前报备状态
            getHomeApply() {
                let obj = {
                    exam_uuid: window.localStorage.getItem("exam_uuid"),
                }
                getHomeApply(obj).then(res => {
                    console.log(res, "报备状态");
                    let resData = res.data;
                    if (resData.code != 200) {
                        return this.$message.error(resData.message);
                    }
                    if (resData.data['prove_pics']) {
                        resData.data['prove_pics'] = resData.data['prove_pics'].split(',');
                        this.formData.prove_pics = resData.data['prove_pics'];
                    }
                    if (resData.data['residence_information']) {
                        resData.data['residence_information'] = JSON.parse(resData.data['residence_information']);
                        this.formData.residence_information = resData.data['residence_information'];
                    } else {
                        this.formData.residence_information['province'] = Number(resData.data['user_info']['schoolprovince']);
                        this.formData.residence_information['city'] = Number(resData.data['user_info']['schoolcity']);
                    }
                    if (resData.data['sojourn_history_14'] && resData.data['sojourn_history_14'] != "{}") {
                        resData.data['sojourn_history_14'] = JSON.parse(resData.data['sojourn_history_14']);
                        if (resData.data['sojourn_history_14'].length > 0) {
                            for (let k in resData.data['sojourn_history_14']) {
                                this.formData.sojourn_history_14.push(resData.data['sojourn_history_14'][k]);
                                this.rules.sojourn_history_14.push(this.sojourn_history_rules);
                            }
                        } else {
                            this.formData.sojourn_history_14.push(this.sojourn_history_obj);
                            this.rules.sojourn_history_14.push(this.sojourn_history_rules);
                        }
                    } else {
                        this.formData.sojourn_history_14.push(this.sojourn_history_obj);
                        this.rules.sojourn_history_14.push(this.sojourn_history_rules);
                    }
                    this.formData.exam_uuid = window.localStorage.getItem("exam_uuid")
                    if (resData.data['real_name']) {
                        this.formData.real_name = resData.data['real_name'];
                    } else {
                        this.formData.real_name = resData.data['user_info']['name'];
                    }
                    if (resData.data['phone']) {
                        this.formData.phone = resData.data['phone'];
                    } else {
                        this.formData.phone = resData.data['user_info']['phone'];
                    }
                    for (let k in this.formData.prove_pics) {
                        let obj = {
                            url: this.formData.prove_pics[k]
                        }
                        this.fileList.push(obj)
                    }
                    this.cityList = this.getCurrentCityLists(this.formData.residence_information.province);
                    this.formData.sojourn_history_14.forEach((item, index) => {
                        this.historyCityList[index] = this.getCurrentCityListsH(item.province);
                    })
                }).catch(err => {
                    console.log(err)
                })
            },
            //获取全国省份
            getProvinceLists() {
                getProvinceList()
                    .then((res) => {
                        if (res.data.code === 200) {
                            this.provinceData = res.data.list;
                            window.localStorage.setItem(
                                "provinceData",
                                JSON.stringify(res.data.list)
                            );
                        } else {
                            this.$message.error(res.data.message);
                        }
                    })
                    .catch((err) => {
                        this.$message.error(err);
                    });
            },
            //获取全国城市
            getCityLists() {
                getCityList()
                    .then((res) => {
                        if (res.data.code === 200) {
                            this.cityData = res.data.list;
                            window.localStorage.setItem(
                                "cityData",
                                JSON.stringify(res.data.list)
                            );
                        } else {
                            this.$message.error(res.data.message);
                        }
                    })
                    .catch((err) => {
                        this.$message.error(err);
                    });
            },
            //选择居住地省份change事件
            selectProvince(val) {
                this.formData.residence_information.city = null;
                this.formData.residence_information.area = null;
                this.formData.residence_information.street = null;
                this.cityList = this.getCurrentCityLists(val);
            },
            //选择居住地城市change事件
            selectCity(val) {
                this.formData.residence_information.area = null;
                this.formData.residence_information.street = null;
            },
            //返回当前省份下的城市列表
            getCurrentCityLists(val) {
                console.log(this.cityData)
                let currentCity = [];
                if (
                    this.cityData == null ||
                    !this.cityData ||
                    Object.values(this.cityData).length == 0
                ) {
                    getCityList()
                        .then((res) => {
                            if (res.data.code === 200) {
                                this.cityData = res.data.list;
                                window.localStorage.setItem(
                                    "cityData",
                                    JSON.stringify(res.data.list)
                                );
                                for (let key in this.cityData) {
                                    if (this.cityData[key].parent_id == val) {
                                        currentCity.push(this.cityData[key]);
                                    }
                                }
                                console.log(11, currentCity)
                                return currentCity;
                            } else {
                                this.$message.error(res.data.message);
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err);
                        });
                } else {
                    for (let key in this.cityData) {
                        if (this.cityData[key].parent_id == val) {
                            currentCity.push(this.cityData[key]);
                        }
                    }
                    console.log(22, currentCity)
                    return currentCity;
                }
                console.log(33, currentCity)
                return currentCity;
            },

            //选择旅居史省份change事件
            selectHistoryProvince(val, index) {
                console.log(val, index)
                this.formData.sojourn_history_14[index].city = null;
                this.formData.sojourn_history_14[index].area = null;
                this.historyCityList[index] = this.getCurrentCityListsH(val);
            },
            //选择旅居史城市change事件
            selectHistoryCity(val, index) {
                this.formData.sojourn_history_14[index].area = null;
            },
            //获取所有旅居史省份和城市
            getProAndCityHistory() {
                let obj = {
                    exam_uuid: window.localStorage.getItem("exam_uuid"),
                }
                getProAndCityHistory(obj).then(res => {
                    console.log(res)
                    if (res.data.code != 200) {
                        return this.$message.error(res.data.message);
                    }
                    this.provinceDataHis = res.data.province_list;
                    this.cityDataHis = res.data.city_list;
                    console.log(this.cityDataHis)
                }).catch(err => {
                    console.log(err)
                })
            },
            //返回当前旅居史省份下的城市列表
            getCurrentCityListsH(val) {
                let currentCity = [];
                if (this.cityDataHis == null ||
                    !this.cityDataHis ||
                    Object.values(this.cityDataHis).length == 0) {
                    getProAndCityHistory({
                        exam_uuid: window.localStorage.getItem("exam_uuid"),
                    })
                        .then((res) => {
                            if (res.data.code == 200) {
                                this.cityDataHis = res.data.city_list;
                                for (let key in this.cityDataHis) {
                                    if (this.cityDataHis[key].parent_id == val) {
                                        currentCity.push(this.cityDataHis[key]);
                                    }
                                }
                                console.log(1, currentCity)
                                return currentCity;
                            } else {
                                this.$message.error(res.data.message);
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err);
                        });
                } else {
                    for (let key in this.cityDataHis) {
                        if (this.cityDataHis[key].parent_id == val) {
                            currentCity.push(this.cityDataHis[key]);
                        }
                    }
                    console.log(2, currentCity);
                    return currentCity;
                }
                console.log(3, currentCity)
                return currentCity;
            },
        },
    };
</script>

<style scoped lang="scss">
    // 面包屑样式
    @import "a/scss/beradcrumb";

    #container {
        width: 100%;
        min-height: 80%;
        margin: 0 auto;
        overflow: auto;

        .el-main {
            max-width: 1300px;
            margin: 6px auto 0px;
            padding: 0 50px;

            .formBox {
                background: #fff;
                padding: 13px 62px;

                .header {
                    height: 84px;
                    border-bottom: 1px solid #E0E0E0;
                    line-height: 84px;
                    font-size: 24px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #333333;
                }

                .prompt_message {
                    padding: 10px 24px;
                    margin: 27px 0 10px;
                    background-color: #F5F5F5;
                    border-radius: 4px;
                    line-height: 27px;
                }

                ::v-deep .el-form {
                    padding: 0 7px;

                    .item-title {
                        padding: 20px 0 10px;
                        font-size: 18px;
                        font-family: PingFang SC;
                        font-weight: 500;
                        color: #000000;
                        line-height: 30px;

                        span {
                            font-size: 14px;
                            font-weight: normal;
                            font-size: 14px;
                            font-family: PingFang SC;
                            font-weight: 500;
                            color: #999999;
                            line-height: 30px;
                        }
                    }

                    .add_place {
                        padding: 18px 0 20px;
                        color: #ee7602;
                        font-size: 16px;
                        font-family: PingFang SC;
                        font-weight: 500;
                        color: #6B4B89;

                        span {
                            cursor: pointer;
                        }
                    }

                    .el-form-item {
                        .el-form-item__label {
                            padding: 0;
                            height: 40px;
                        }

                        &.label_no {
                            .el-form-item__label::before {
                                content: '';
                            }
                        }

                        &.btn {
                            text-align: center;
                            padding: 40px 0 60px;
                        }

                        .el-button.confirm {
                            width: 200px;
                            height: 48px;
                            background-color: #3C0765;
                            border-radius: 4px;
                            font-size: 16px;
                            font-family: PingFang SC;
                            font-weight: 600;
                            color: #FFFFFF;
                            opacity: 0.8;

                            &:hover {
                                opacity: 1;
                            }
                        }
                    }
                }

                ::v-deep .el-col {
                    position: relative;

                    &.cc {
                        font-size: 14px;
                        font-family: PingFang SC;
                        font-weight: 500;
                        color: #999999;
                        line-height: 24px;
                    }

                    &.el-col-8 {
                        margin-top: 24px;

                        .el-upload {
                            width: 100%;

                            .el-upload-dragger {
                                width: 100%;
                                opacity: 0.29;
                                border: 2px dashed #000000;

                                .icon {
                                    font-size: 35px;
                                    color: #afa7a7;
                                    margin-top: 40px;
                                }

                                .el-upload__tip {
                                    height: 18px;
                                    font-size: 13px;
                                    font-family: PingFang SC;
                                    font-weight: 500;
                                    color: rgba(35, 35, 39, 0.84);
                                    line-height: 18px;
                                }

                                .el-upload__text {
                                    height: 20px;
                                    font-size: 14px;
                                    font-family: PingFang SC;
                                    font-weight: 500;
                                    color: #3C0765;
                                    line-height: 20px;
                                    text-decoration: underline;
                                }
                            }

                            &:hover {
                                .el-upload-dragger {
                                    border-color: #563279;
                                    opacity: 1;

                                    .el-upload__text {
                                        color: #563279;
                                    }
                                }
                            }
                        }

                        .el-icon-close {
                            cursor: pointer;
                            position: absolute;
                            right: 21px;
                            top: 10px;
                            border-radius: 50%;
                            padding: 5px;
                            color: #ffffff;
                            /*background-color: rgba(225, 225, 225, 0.3);*/
                            background-color: #000000;
                            display: none;
                        }

                        &:hover {
                            .el-icon-close {
                                display: block;
                            }
                        }

                        .el-image {
                            width: 100%;
                            height: 180px;
                            border-radius: 6px;
                        }
                    }
                }
            }
        }
    }
</style>

